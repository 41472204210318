@charset "UTF-8";
@tailwind base;
@tailwind components;

.typetester-overlay-container {
  transition: transform 1s ease-in-out;
  /* transform: scale(180%); */
  transform: scale3d(1.8, 1.8, 1);
  pointer-events: none;
}

/* Together with the pointer-events: none; above this should make the page below the HUD interactive to mouse events again. No idea what it does, my friend Mihai gave me this code bit :D */

.typetester-overlay-container > div {
  pointer-events: initial;
}

.typetester-overlay-active .typetester-overlay-container {
  transition: transform 0.2s ease-in-out;
  transform: scale(100%);
}

.tt__bg {
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
}

.typetester-overlay-active .tt__bg {
  opacity: 1;
}

/* body:not(.typetester-overlay-active) .tt__sample {
  display: none !important;
}

body:not(.typetester-overlay-active) .tt__tv {
  display: none !important;
} */

/* div[data-component*="preset"].hidden {
  transform: translateY(-100%);
  transition: transform 1s;
}

div[data-component*="preset"]:not(.hidden) {
  transform: translateY(0%);
  transition: transform 1s;
} */

.typetester-control-area-deactivated.typetester-control-area-left {
  transition: transform 0.2s ease-in-out;
  transform: translateX(-120%);
}

.typetester-control-area-deactivated.typetester-control-area-bottom {
  transition: transform 0.2s ease-in-out;
  @apply translate-y-[420%] md:translate-y-[110%];
}

.typetester-control-area-deactivated.typetester-control-area-bottom-mobileonly {
  transition: transform 0.2s ease-in-out;
  @apply translate-y-[110%] md:translate-y-0;
}

.typetester-element-container {
  @apply bg-black border-2 border-gray-dk;
}

.typetester-status-dot {
  @apply inline-block w-3 h-3 mb-[2px] lg:mb-[4px] rounded-full;
}

.typetester-nav-dot {
  @apply block w-4 h-4 mb-[4px] rounded-full;
}

.typetester-dot__inactive {
  @apply bg-blue-md;
}

.typetester-dot__active {
  @apply bg-green;
}

/* Autopilot Desktop */

.autopilot:hover {
  @apply text-blue-lt;
}

.typetester-icon-play-inner {
  @apply fill-blue-dk;
}

.autopilot:hover .typetester-icon-play-inner {
  @apply fill-blue-lt;
}

.typetester-icon-play,
.typetester-icon-stop {
  transition: transform 0.2s;
}

.autopilot-active .autopilot .typetester-icon-play {
  @apply translate-y-[100%];
  transition: transform 0.2s;
}

.autopilot-active .autopilot .typetester-icon-stop {
  @apply translate-y-[0%];
  transition: transform 0.2s;
}

.autopilot-active .autopilot .typetester-autoplay-icon-container {
  @apply bg-blue;
}
.autopilot-active .autopilot .typetester-autoplay-info-container {
  @apply bg-blue-md;
}
.autopilot-active .autopilot:hover .typetester-autoplay-icon-container {
  @apply bg-blue-lt;
}
.autopilot-active .autopilot:hover .typetester-autoplay-info-container {
  @apply bg-blue;
}

/* Preset */

.typetester-preset-button {
  @apply flex flex-col justify-center items-center flex-grow border border-blue m-2 p-3 text-center leading-none;
}

.typetester-preset-button div {
  @apply h-[30px] md:h-auto md:min-h-[24px] border border-blue bg-blue-dk mb-3 w-full;
}

.typetester-preset-button:hover {
  @apply cursor-pointer;
}

.typetester-preset-button:hover div {
  @apply bg-green;
}

.typetester-preset-button.typetester-preset-active {
  @apply bg-blue-md text-blue-lt;
}

.typetester-preset-button.typetester-preset-active div {
  @apply bg-green;
}

/* Features */

.typetester-features-toggle-indicator {
  @apply w-[15px] h-full bg-blue-lt translate-x-[0px];
  transition: transform 0.1s;
}

.typetester-features-toggle-indicator-on {
  @apply bg-green translate-x-[15px];
  transition: transform 0.1s;
}

/* Powersteering */

/* Desktop */

.typetester-powersteering-active div {
  @apply border-green bg-green;
}

.typetester-powersteering-active div * {
  @apply border-green text-green bg-black;
}

/* Typetester Slnt Angle Indicator */

.typetester-desktop-angle-indicator {
  transform-origin: 71px 89px;
  @apply hover:cursor-pointer p-10;
}

/* WIP need to try using Tailwind’s parent selector thingie */
.typetester-desktop-angle-indicator circle,
.typetester-desktop-angle-indicator stroke {
  @apply hover:fill-blue-lt;
}

.typetester-desktop-angle-active-angle {
  @apply text-green;
}

.typetester-desktop-weight-active-weight {
  @apply text-green;
}

/* Typetester Wght Axis Slider */

/* Desktop Version */

.typetester-desktop-weight-slider-container {
}

.typetester-desktop-weight-slider {
  @apply appearance-none w-full h-full bg-blue-dk outline-none;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.typetester-desktop-weight-slider::-webkit-slider-thumb {
  @apply appearance-none w-[14px] h-[14px] bg-green rounded-full cursor-pointer relative -mt-[50px] border-[10px] border-solid border-blue box-content z-50;
}

.typetester-desktop-weight-slider::-moz-range-thumb {
  @apply appearance-none w-[45px] h-[45px] bg-blue-md border-4 border-blue rounded-full cursor-pointer;
}

.typetester-desktop-weight-slider-background {
  @apply absolute top-0 left-0 right-0 bottom-0 m-3 z-0;
  background-image: url("../../images/hud/stats-full.gif");
  background-repeat: repeat;
}

/* Mobile Version */

.typetester-mobile-weight-slider {
  /* @apply appearance-none w-full h-full bg-blue-dk outline-none; */
  @apply appearance-none h-[8px] rotate-90 origin-left bg-blue-dk border-2 border-blue ml-[9px] mt-3;
  width: calc(var(--visible-viewport-height) - 310px);
}

.typetester-mobile-weight-slider::-webkit-slider-thumb {
  @apply appearance-none w-[16px] h-[16px] border-solid border-[4px] border-blue bg-blue-md rounded-full cursor-pointer -mt-[6px];
}

.typetester-mobile-weight-slider::-webkit-slider-runnable-track {
  @apply h-[4px];
  background-image: url("../../images/hud/stats-full-blue.gif");
  background-repeat: repeat;
}

.typetester-mobile-angle-slider {
  @apply appearance-none h-[8px] border-2 border-blue bg-blue-dk outline-none;
}

.typetester-mobile-angle-slider::-webkit-slider-thumb {
  @apply appearance-none w-[16px] h-[16px] border-solid border-[4px] border-blue bg-blue-md rounded-full cursor-pointer -mt-[6px];
}

.typetester-mobile-angle-slider::-webkit-slider-runnable-track {
  @apply h-[4px];
  background-image: url("../../images/hud/stats-full-blue.gif");
  background-repeat: repeat;
}

@keyframes autopilot-def {
  0% {
    font-variation-settings: "wght" 100, "slnt" 0;
  }

  20% {
    font-variation-settings: "wght" 100, "slnt" -45;
  }

  40% {
    font-variation-settings: "wght" 900, "slnt" -45;
  }

  60% {
    font-variation-settings: "wght" 900, "slnt" 45;
  }

  80% {
    font-variation-settings: "wght" 900, "slnt" 0;
  }

  100% {
    font-variation-settings: "wght" 100, "slnt" 0;
  }
}

.tt__sample {
  will-change: font-variation-settings;
  font-feature-settings: var(--user-font-feature-settings);
  font-variation-settings: "wght" var(--user-wght), "slnt" var(--user-slnt);
}

.autopilot-active .tt__sample[data-active-preset="DEF"] {
  animation: autopilot-def 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
}

.tt__tv.enter-transition,
.tt__sample.enter-transition {
  opacity: 0;
  transform: scaleX(1);
}

.tt__tv.enter-transition-active,
.tt__sample.enter-transition-active {
  transition: opacity 0.25s ease, transform 0.25s ease;
  opacity: 1;
  transform: scaleX(1);
}

.tt__tv.exit-transition,
.tt__sample.exit-transition {
  opacity: 1;
  transform: scaleX(1);
}

.tt__tv.exit-transition-active,
.tt__sample.exit-transition-active {
  transition: opacity 0.25s ease, transform 0.25s ease;
  opacity: 0;
  transform: scaleX(0);
}

/* TV PRESET IMPLOSION */

.tt-tvsample-imp {
  @apply uppercase text-[11vw] md:text-[11vw] lg:text-[9vw] flex flex-col w-full h-full justify-center items-center overflow-hidden;
}

.tt-tvsample-imp span {
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.tt-imp-line-1,
.tt-imp-line-2,
.tt-imp-line-3,
.tt-imp-line-4 {
  @apply leading-[80%] text-blue;
  font-variation-settings: "wght" 400, "slnt" 0;
}

/* IMPLOSION ANIMATION LINE 1 */

@keyframes tt-imp-line-1-letter1 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -45;
  }
}

@keyframes tt-imp-line-1-letter2 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -36;
  }
}

@keyframes tt-imp-line-1-letter3 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -27;
  }
}

@keyframes tt-imp-line-1-letter4 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -18;
  }
}

@keyframes tt-imp-line-1-letter5 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -9;
  }
}

@keyframes tt-imp-line-1-letter6 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 9;
  }
}

@keyframes tt-imp-line-1-letter7 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 18;
  }
}

@keyframes tt-imp-line-1-letter8 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 27;
  }
}

@keyframes tt-imp-line-1-letter9 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 36;
  }
}

@keyframes tt-imp-line-1-letter10 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 45;
  }
}

.tt-imp-line-1 span:nth-child(1) {
  animation-name: tt-imp-line-1-letter1;
}

.tt-imp-line-1 span:nth-child(2) {
  animation-name: tt-imp-line-1-letter2;
}

.tt-imp-line-1 span:nth-child(3) {
  animation-name: tt-imp-line-1-letter3;
}

.tt-imp-line-1 span:nth-child(4) {
  animation-name: tt-imp-line-1-letter4;
}

.tt-imp-line-1 span:nth-child(5) {
  animation-name: tt-imp-line-1-letter5;
}

.tt-imp-line-1 span:nth-child(6) {
  animation-name: tt-imp-line-1-letter6;
}

.tt-imp-line-1 span:nth-child(7) {
  animation-name: tt-imp-line-1-letter7;
}

.tt-imp-line-1 span:nth-child(8) {
  animation-name: tt-imp-line-1-letter8;
}

.tt-imp-line-1 span:nth-child(9) {
  animation-name: tt-imp-line-1-letter9;
}

.tt-imp-line-1 span:nth-child(10) {
  animation-name: tt-imp-line-1-letter10;
}

/* IMPLOSION ANIMATION LINE 2 */

@keyframes tt-imp-line-2-letter1 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -45;
  }
}

@keyframes tt-imp-line-2-letter2 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -33.75;
  }
}

@keyframes tt-imp-line-2-letter3 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -22.5;
  }
}

@keyframes tt-imp-line-2-letter4 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -11.25;
  }
}

@keyframes tt-imp-line-2-letter5 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 0;
  }
}

@keyframes tt-imp-line-2-letter6 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 11.25;
  }
}

@keyframes tt-imp-line-2-letter7 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 22.5;
  }
}

@keyframes tt-imp-line-2-letter8 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 33.75;
  }
}

@keyframes tt-imp-line-2-letter9 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 45;
  }
}

.tt-imp-line-2 span:nth-child(1) {
  animation-name: tt-imp-line-2-letter1;
}

.tt-imp-line-2 span:nth-child(2) {
  animation-name: tt-imp-line-2-letter2;
}

.tt-imp-line-2 span:nth-child(3) {
  animation-name: tt-imp-line-2-letter3;
}

.tt-imp-line-2 span:nth-child(4) {
  animation-name: tt-imp-line-2-letter4;
}

.tt-imp-line-2 span:nth-child(5) {
  animation-name: tt-imp-line-2-letter5;
}

.tt-imp-line-2 span:nth-child(6) {
  animation-name: tt-imp-line-2-letter6;
}

.tt-imp-line-2 span:nth-child(7) {
  animation-name: tt-imp-line-2-letter7;
}

.tt-imp-line-2 span:nth-child(8) {
  animation-name: tt-imp-line-2-letter8;
}

.tt-imp-line-2 span:nth-child(9) {
  animation-name: tt-imp-line-2-letter9;
}

.tt-imp-line-2 span:nth-child(10) {
  animation-name: tt-imp-line-2-letter10;
}

/* IMPLOSION ANIMATION LINE 3 */

@keyframes tt-imp-line-3-letter1 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 45;
  }
}

@keyframes tt-imp-line-3-letter2 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 36;
  }
}

@keyframes tt-imp-line-3-letter3 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 27;
  }
}

@keyframes tt-imp-line-3-letter4 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 18;
  }
}

@keyframes tt-imp-line-3-letter5 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 9;
  }
}

@keyframes tt-imp-line-3-letter6 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -9;
  }
}

@keyframes tt-imp-line-3-letter7 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -18;
  }
}

@keyframes tt-imp-line-3-letter8 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -27;
  }
}

@keyframes tt-imp-line-3-letter9 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -36;
  }
}

@keyframes tt-imp-line-3-letter10 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -45;
  }
}

.tt-imp-line-3 span:nth-child(1) {
  animation-name: tt-imp-line-3-letter1;
}

.tt-imp-line-3 span:nth-child(2) {
  animation-name: tt-imp-line-3-letter2;
}

.tt-imp-line-3 span:nth-child(3) {
  animation-name: tt-imp-line-3-letter3;
}

.tt-imp-line-3 span:nth-child(4) {
  animation-name: tt-imp-line-3-letter4;
}

.tt-imp-line-3 span:nth-child(5) {
  animation-name: tt-imp-line-3-letter5;
}

.tt-imp-line-3 span:nth-child(6) {
  animation-name: tt-imp-line-3-letter6;
}

.tt-imp-line-3 span:nth-child(7) {
  animation-name: tt-imp-line-3-letter7;
}

.tt-imp-line-3 span:nth-child(8) {
  animation-name: tt-imp-line-3-letter8;
}

.tt-imp-line-3 span:nth-child(9) {
  animation-name: tt-imp-line-3-letter9;
}

.tt-imp-line-3 span:nth-child(10) {
  animation-name: tt-imp-line-3-letter10;
}
/* IMPLOSION ANIMATION LINE 4 */

@keyframes tt-imp-line-4-letter1 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 45;
  }
}

@keyframes tt-imp-line-4-letter2 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 37.5;
  }
}

@keyframes tt-imp-line-4-letter3 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 30;
  }
}

@keyframes tt-imp-line-4-letter4 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 22.5;
  }
}

@keyframes tt-imp-line-4-letter5 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 15;
  }
}

@keyframes tt-imp-line-4-letter6 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 7.5;
  }
}

@keyframes tt-imp-line-4-letter7 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -7.5;
  }
}

@keyframes tt-imp-line-4-letter8 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -15;
  }
}

@keyframes tt-imp-line-4-letter9 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -22.5;
  }
}

@keyframes tt-imp-line-4-letter10 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -30;
  }
}

@keyframes tt-imp-line-4-letter11 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -37.5;
  }
}

@keyframes tt-imp-line-4-letter12 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -45;
  }
}

.tt-imp-line-4 span:nth-child(1) {
  animation-name: tt-imp-line-4-letter1;
}

.tt-imp-line-4 span:nth-child(2) {
  animation-name: tt-imp-line-4-letter2;
}

.tt-imp-line-4 span:nth-child(3) {
  animation-name: tt-imp-line-4-letter3;
}

.tt-imp-line-4 span:nth-child(4) {
  animation-name: tt-imp-line-4-letter4;
}

.tt-imp-line-4 span:nth-child(5) {
  animation-name: tt-imp-line-4-letter5;
}

.tt-imp-line-4 span:nth-child(6) {
  animation-name: tt-imp-line-4-letter6;
}

.tt-imp-line-4 span:nth-child(7) {
  animation-name: tt-imp-line-4-letter7;
}

.tt-imp-line-4 span:nth-child(8) {
  animation-name: tt-imp-line-4-letter8;
}

.tt-imp-line-4 span:nth-child(9) {
  animation-name: tt-imp-line-4-letter9;
}

.tt-imp-line-4 span:nth-child(10) {
  animation-name: tt-imp-line-4-letter10;
}

.tt-imp-line-4 span:nth-child(11) {
  animation-name: tt-imp-line-4-letter11;
}

.tt-imp-line-4 span:nth-child(12) {
  animation-name: tt-imp-line-4-letter12;
}

/* IMPLOSION ANIMATION LINE 4 */

@keyframes tt-imp-line-4-letter1 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 45;
  }
}

@keyframes tt-imp-line-4-letter2 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 37.5;
  }
}

@keyframes tt-imp-line-4-letter3 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 30;
  }
}

@keyframes tt-imp-line-4-letter4 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 22.5;
  }
}

@keyframes tt-imp-line-4-letter5 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 15;
  }
}

@keyframes tt-imp-line-4-letter6 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 7.5;
  }
}

@keyframes tt-imp-line-4-letter7 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -7.5;
  }
}

@keyframes tt-imp-line-4-letter8 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -15;
  }
}

@keyframes tt-imp-line-4-letter9 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -22.5;
  }
}

@keyframes tt-imp-line-4-letter10 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -30;
  }
}

@keyframes tt-imp-line-4-letter11 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -37.5;
  }
}

@keyframes tt-imp-line-4-letter12 {
  0% {
    font-variation-settings: "wght" 400, "slnt" 0;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" -45;
  }
}

.tt-imp-line-4 span:nth-child(1) {
  animation-name: tt-imp-line-4-letter1;
}

.tt-imp-line-4 span:nth-child(2) {
  animation-name: tt-imp-line-4-letter2;
}

.tt-imp-line-4 span:nth-child(3) {
  animation-name: tt-imp-line-4-letter3;
}

.tt-imp-line-4 span:nth-child(4) {
  animation-name: tt-imp-line-4-letter4;
}

.tt-imp-line-4 span:nth-child(5) {
  animation-name: tt-imp-line-4-letter5;
}

.tt-imp-line-4 span:nth-child(6) {
  animation-name: tt-imp-line-4-letter6;
}

.tt-imp-line-4 span:nth-child(7) {
  animation-name: tt-imp-line-4-letter7;
}

.tt-imp-line-4 span:nth-child(8) {
  animation-name: tt-imp-line-4-letter8;
}

.tt-imp-line-4 span:nth-child(9) {
  animation-name: tt-imp-line-4-letter9;
}

.tt-imp-line-4 span:nth-child(10) {
  animation-name: tt-imp-line-4-letter10;
}

.tt-imp-line-4 span:nth-child(11) {
  animation-name: tt-imp-line-4-letter11;
}

.tt-imp-line-4 span:nth-child(12) {
  animation-name: tt-imp-line-4-letter12;
}

/* TV PRESET STARWAYS */

.tt-tvsample-sta {
  @apply uppercase text-[25vh] portrait:text-[14vh] flex flex-col w-full h-full items-start -mt-[10px];
  --base-delay-sta: 0.2s;
}

.tt-tvsample-sta .tt-sta-line {
  animation-duration: var(--sta-duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: scale(0.1) translateY(0) translateX(-50%);
  font-feature-settings: "ss04" 1, "kern" 1;
  @apply absolute top-0 left-[50%];
}

.tt-sta-line {
  @apply leading-[100%] text-blue;
  font-variation-settings: "wght" 400, "slnt" 0;
}

.tt-sta-line span:nth-child(1) {
  font-variation-settings: "wght" 400, "slnt" 45;
}

.tt-sta-line span:nth-child(2) {
  font-variation-settings: "wght" 400, "slnt" 33.75;
}

.tt-sta-line span:nth-child(3) {
  font-variation-settings: "wght" 400, "slnt" 22.5;
}

.tt-sta-line span:nth-child(4) {
  font-variation-settings: "wght" 400, "slnt" 11.25;
}

.tt-sta-line span:nth-child(5) {
  font-variation-settings: "wght" 400, "slnt" -11.25;
}

.tt-sta-line span:nth-child(6) {
  font-variation-settings: "wght" 400, "slnt" -22.5;
}

.tt-sta-line span:nth-child(7) {
  font-variation-settings: "wght" 400, "slnt" -33.75;
}

.tt-sta-line span:nth-child(8) {
  font-variation-settings: "wght" 400, "slnt" -45;
}

.tt-tvsample-sta {
  --sta-scale-0: 0.1;
  --sta-scale-100: 1;
  --sta-translateY-0: 0vh;
  --sta-translateY-100: 100vh;
  --sta-stagger: 1s;
  --sta-duration: 8s;
}

@media (orientation: portrait) {
  .tt-tvsample-sta {
    --sta-duration: 13s;
  }
}

/* :md+ screens */
/* @media (min-width: 768px) {
  .tt-tvsample-sta {
    --sta-scale-0: 0.1;
    --sta-scale-100: 1;
    --sta-translateY-0: 90vh;
    --sta-translateY-100: 110vh;
  }
} */

/* :lg+ screens */
/* @media (min-width: 1024px) {
  .tt-tvsample-sta {
    --sta-scale-0: 0.1;
    --sta-scale-100: 1;
    --sta-translateY-0: 0vh;
    --sta-translateY-100: 105vh;
  }
} */

@keyframes anim-tt-sta-line {
  0% {
    transform: translateX(-50%) scale(var(--sta-scale-0))
      translateY(var(--sta-translateY-0));
    line-height: 100%;
  }
  100% {
    transform: translateX(-50%) scale(var(--sta-scale-100))
      translateY(var(--sta-translateY-100));
    line-height: 100%;
  }
}

.tt-sta-line {
  transform-origin: top center;
  animation-name: anim-tt-sta-line;
  width: fit-content;
}

.tt-sta-line-1 {
  animation-delay: calc(var(--sta-stagger) * 0);
}

.tt-sta-line-2 {
  animation-delay: calc(var(--sta-stagger) * 1);
}

.tt-sta-line-3 {
  animation-delay: calc(var(--sta-stagger) * 2);
}

.tt-sta-line-4 {
  animation-delay: calc(var(--sta-stagger) * 3);
}

.tt-sta-line-5 {
  animation-delay: calc(var(--sta-stagger) * 4);
}

.tt-sta-line-6 {
  animation-delay: calc(var(--sta-stagger) * 5);
}

.tt-sta-line-7 {
  animation-delay: calc(var(--sta-stagger) * 6);
}

.tt-sta-line-8 {
  animation-delay: calc(var(--sta-stagger) * 7);
}

.tt-sta-line-9 {
  animation-delay: calc(var(--sta-stagger) * 8);
  @apply landscape:hidden;
}

.tt-sta-line-10 {
  animation-delay: calc(var(--sta-stagger) * 9);
  @apply landscape:hidden;
}

.tt-sta-line-11 {
  animation-delay: calc(var(--sta-stagger) * 10);
  @apply landscape:hidden;
}

.tt-sta-line-12 {
  animation-delay: calc(var(--sta-stagger) * 11);
  @apply landscape:hidden;
}

.tt-sta-line-13 {
  animation-delay: calc(var(--sta-stagger) * 12);
  @apply landscape:hidden;
}

.tt-sta-line-14 {
  animation-delay: calc(var(--sta-stagger) * 13);
  @apply hidden;
}

.tt-sta-line-15 {
  animation-delay: calc(var(--sta-stagger) * 14);
  @apply hidden;
}

.tt-sta-line-16 {
  animation-delay: calc(var(--sta-stagger) * 15);
  @apply hidden;
}

.tt-sta-line-17 {
  animation-delay: calc(var(--sta-stagger) * 16);
  @apply hidden;
}

/* TV PRESET DOMINO */

.tt-tvsample-dom {
  @apply uppercase text-blue flex flex-col w-full h-full justify-center items-center leading-[0.8] overflow-hidden;
  --wght: 900;
  --slnt: 0;
  --base-delay: 0.1s;
  font-variation-settings: "wght" var(--wght), "slnt" var(--slnt);
}

.tt-tvsample-dom div span {
  animation-duration: 5s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.tt-dom-line-1 {
  @apply text-[21vw] md:text-[20vw] lg:text-[19vw] tracking-tighter;
}

.tt-dom-line-2 {
  @apply text-[12.1vw] md:text-[11.575vw] lg:text-[10.975vw] tracking-tighter;
}

.tt-dom-line-3 {
  @apply text-[6.15vw] md:text-[5.875vw] lg:text-[5.55vw] tracking-tighter;
}

.tt-dom-line-1 span:nth-child(1) {
  --multiplier: 1;
  animation-name: anim-tt-dom-line-1-1;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-1 span:nth-child(2) {
  --multiplier: 2;
  animation-name: anim-tt-dom-line-1-2;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-1 span:nth-child(3) {
  --multiplier: 3;
  animation-name: anim-tt-dom-line-1-3;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-1 span:nth-child(4) {
  --multiplier: 4;
  animation-name: anim-tt-dom-line-1-4;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-1 span:nth-child(5) {
  --multiplier: 5;
  animation-name: anim-tt-dom-line-1-5;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-1 span:nth-child(6) {
  --multiplier: 6;
  animation-name: anim-tt-dom-line-1-6;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-1 span:nth-child(7) {
  --multiplier: 7;
  animation-name: anim-tt-dom-line-1-7;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

@keyframes anim-tt-dom-line-1-1 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 6.4;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 6.4;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-1-2 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 12.8;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 12.8;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-1-3 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 19.28;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 19.28;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-1-4 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 25.7;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 25.7;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-1-5 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 32.1;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 32.1;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-1-6 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 38.6;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 38.6;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-1-7 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

.tt-dom-line-2 span:nth-child(1) {
  --multiplier: 10;
  animation-name: anim-tt-dom-line-2-1;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(2) {
  --multiplier: 9;
  animation-name: anim-tt-dom-line-2-2;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(3) {
  --multiplier: 8;
  animation-name: anim-tt-dom-line-2-3;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(4) {
  --multiplier: 7;
  animation-name: anim-tt-dom-line-2-4;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(5) {
  --multiplier: 6;
  animation-name: anim-tt-dom-line-2-5;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(6) {
  --multiplier: 5;
  animation-name: anim-tt-dom-line-2-6;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(7) {
  --multiplier: 4;
  animation-name: anim-tt-dom-line-2-7;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(9) {
  --multiplier: 3;
  animation-name: anim-tt-dom-line-2-9;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(10) {
  --multiplier: 2;
  animation-name: anim-tt-dom-line-2-10;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

.tt-dom-line-2 span:nth-child(11) {
  --multiplier: 1;
  animation-name: anim-tt-dom-line-2-11;
  animation-delay: calc(1 * var(--multiplier) * var(--base-delay));
}

@keyframes anim-tt-dom-line-2-1 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-2 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -40.5;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -40.5;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-3 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -36;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -36;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-4 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -31.5;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -31.5;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-5 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -27;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -27;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-6 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -22.5;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -22.5;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-7 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -18;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -18;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-9 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -13.5;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -13.5;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-10 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -9;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -9;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-2-11 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" -4.5;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" -4.5;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

.tt-dom-line-3 span:nth-child(1) {
  --multiplier: 1;
  animation-name: anim-tt-dom-line-3-1;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(2) {
  --multiplier: 2;
  animation-name: anim-tt-dom-line-3-2;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(3) {
  --multiplier: 3;
  animation-name: anim-tt-dom-line-3-3;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(4) {
  --multiplier: 4;
  animation-name: anim-tt-dom-line-3-4;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(5) {
  --multiplier: 5;
  animation-name: anim-tt-dom-line-3-5;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(6) {
  --multiplier: 6;
  animation-name: anim-tt-dom-line-3-6;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(7) {
  --multiplier: 7;
  animation-name: anim-tt-dom-line-3-7;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(8) {
  --multiplier: 8;
  animation-name: anim-tt-dom-line-3-8;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(9) {
  --multiplier: 9;
  animation-name: anim-tt-dom-line-3-9;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(10) {
  --multiplier: 10;
  animation-name: anim-tt-dom-line-3-10;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(11) {
  --multiplier: 11;
  animation-name: anim-tt-dom-line-3-11;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(12) {
  --multiplier: 12;
  animation-name: anim-tt-dom-line-3-12;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(13) {
  --multiplier: 13;
  animation-name: anim-tt-dom-line-3-13;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(15) {
  --multiplier: 15;
  animation-name: anim-tt-dom-line-3-15;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(16) {
  --multiplier: 16;
  animation-name: anim-tt-dom-line-3-16;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(17) {
  --multiplier: 17;
  animation-name: anim-tt-dom-line-3-17;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(18) {
  --multiplier: 18;
  animation-name: anim-tt-dom-line-3-18;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(19) {
  --multiplier: 19;
  animation-name: anim-tt-dom-line-3-19;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(20) {
  --multiplier: 20;
  animation-name: anim-tt-dom-line-3-20;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(21) {
  --multiplier: 21;
  animation-name: anim-tt-dom-line-3-21;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(22) {
  --multiplier: 22;
  animation-name: anim-tt-dom-line-3-22;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(23) {
  --multiplier: 23;
  animation-name: anim-tt-dom-line-3-23;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

.tt-dom-line-3 span:nth-child(24) {
  --multiplier: 24;
  animation-name: anim-tt-dom-line-3-24;
  animation-delay: calc(var(--multiplier) * var(--base-delay));
}

@keyframes anim-tt-dom-line-3-1 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 2.045;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 2.045;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-2 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 4.09;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 4.09;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-3 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 6.615;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 6.615;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-4 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 8.18;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 8.18;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-5 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 10.225;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 10.225;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-6 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 12.27;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 12.27;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-7 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 14.315;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 14.315;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-8 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 16.36;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 16.36;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-9 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 18.405;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 18.405;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-10 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 20.45;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 20.45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-11 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 22.495;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 22.495;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-12 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 24.54;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 24.54;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-13 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 26.585;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 26.585;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-15 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 28.63;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 28.63;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-16 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 30.675;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 30.675;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-17 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 32.72;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 32.72;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-18 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 34.765;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 34.765;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-19 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 36.81;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 36.81;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-21 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 38.885;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 38.885;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-22 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 40.9;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 40.9;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-23 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 42.945;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 42.945;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-dom-line-3-24 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  20% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  80% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

/* TV PRESET BOUNCE */

.tt-tvsample-bou {
  @apply uppercase text-blue flex flex-col w-full h-full justify-center items-center leading-[0.8] overflow-hidden;
  --wght: 100;
  --slnt: 0;
  font-variation-settings: "wght" var(--wght), "slnt" var(--slnt);
}

.tt-tvsample-bou div {
  @apply text-[14vw] md:text-[14.5vw] lg:text-[11vw] tracking-tighter;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.tt-bou-line-1 {
  animation-name: anim-tt-bou-line-1;
}

.tt-bou-line-2 {
  animation-name: anim-tt-bou-line-2;
}

.tt-bou-line-3 {
  animation-name: anim-tt-bou-line-3;
}

.tt-bou-line-4 {
  animation-name: anim-tt-bou-line-4;
}

@keyframes anim-tt-bou-line-1 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
}

@keyframes anim-tt-bou-line-2 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
}

@keyframes anim-tt-bou-line-3 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
}

@keyframes anim-tt-bou-line-4 {
  0% {
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  100% {
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
}

/* TV PRESET R_0 */

.tt-tvsample-r_0 {
  @apply uppercase text-blue flex flex-col w-full h-full justify-center items-center leading-[0.8] overflow-visible lg:mt-[50px];
  --wght: 100;
  --slnt: 0;
  font-variation-settings: "wght" var(--wght), "slnt" var(--slnt);
}

.tt-tvsample-r_0 div {
  @apply text-[20vw] md:text-[18.5vw] lg:text-[9vw] tracking-tighter;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.tt-r_0-line-1 {
  animation-name: anim-tt-r_0-line-1;
}

.tt-r_0-line-2 {
  animation-name: anim-tt-r_0-line-2;
}

.tt-r_0-line-3 {
  animation-name: anim-tt-r_0-line-3;
}

.tt-r_0-line-4 {
  animation-name: anim-tt-r_0-line-4;
}

.tt-r_0-line-5 {
  animation-name: anim-tt-r_0-line-5;
}

.tt-r_0-line-6 {
  animation-name: anim-tt-r_0-line-6;
}

@keyframes anim-tt-r_0-line-1 {
  0% {
    font-variation-settings: "wght" 100, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 100, "slnt" 45;
  }
}

@keyframes anim-tt-r_0-line-2 {
  0% {
    font-variation-settings: "wght" 300, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 300, "slnt" 45;
  }
}

@keyframes anim-tt-r_0-line-3 {
  0% {
    font-variation-settings: "wght" 400, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 400, "slnt" 45;
  }
}

@keyframes anim-tt-r_0-line-4 {
  0% {
    font-variation-settings: "wght" 500, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 500, "slnt" 45;
  }
}

@keyframes anim-tt-r_0-line-5 {
  0% {
    font-variation-settings: "wght" 700, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 700, "slnt" 45;
  }
}

@keyframes anim-tt-r_0-line-6 {
  0% {
    font-variation-settings: "wght" 900, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 900, "slnt" 45;
  }
}

/* TV PRESET ENERGY */

.tt-tvsample-ene {
  @apply uppercase text-blue flex flex-col w-full h-full justify-center items-center leading-[1.1] text-[21vw] md:text-[20vw] lg:text-[5.5vw] lg:pt-[90px] overflow-visible;
  --ltr-spc: -0.05em;
  --ltr-spc-wide: 1em;
  --wght: 400;
  --slnt: 0;
  --base-delay: 0.1s;
  font-variation-settings: "wght" var(--wght), "slnt" var(--slnt);
  letter-spacing: var(--ltr-spc);
}

.tt-tvsample-ene div span {
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

/* .tt-ene-line-1:nth-of-type(even) {
  animation-delay: 0.5s;
}

.tt-ene-line-1:nth-of-type(n+3) {
  animation-delay: 0.75s;
}

.tt-ene-line-1:nth-of-type(2n+2) {
  animation-delay: 0.25s;
} */

.tt-ene-9lt span:nth-child(odd) {
  animation-name: anim-tt-ene-9lt-1;
}

.tt-ene-9lt span:nth-child(even) {
  animation-name: anim-tt-ene-9lt-2;
}

@keyframes anim-tt-ene-9lt-1 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  25% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  40% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  65% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  80% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-ene-9lt-2 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  30% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  70% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

.tt-ene-6lt span:nth-child(even) {
  animation-name: anim-tt-ene-6lt-1;
}

.tt-ene-6lt span:nth-child(odd) {
  animation-name: anim-tt-ene-6lt-2;
}

@keyframes anim-tt-ene-6lt-1 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  25% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  40% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  65% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  80% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-ene-6lt-2 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  30% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  70% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

.tt-ene-7lt span:nth-child(even) {
  animation-name: anim-tt-ene-7lt-1;
}

.tt-ene-7lt span:nth-child(odd) {
  animation-name: anim-tt-ene-7lt-2;
}

@keyframes anim-tt-ene-7lt-1 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  25% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  40% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  65% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  80% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-ene-7lt-2 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  30% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  70% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

.tt-ene-10lt span:nth-child(odd) {
  animation-name: anim-tt-ene-10lt-1;
}

.tt-ene-10lt span:nth-child(even) {
  animation-name: anim-tt-ene-10lt-2;
}

@keyframes anim-tt-ene-10lt-1 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  25% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  40% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  65% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  80% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

@keyframes anim-tt-ene-10lt-2 {
  0% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  10% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  30% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" -45;
  }
  70% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 45;
  }
  90% {
    letter-spacing: var(--ltr-spc-wide);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
  100% {
    letter-spacing: var(--ltr-spc);
    font-variation-settings: "wght" var(--wght), "slnt" 0;
  }
}

/* Important to be able to pause stuff ;) */

.tt:not(.autopilot-active) .tt__tv * {
  animation-play-state: paused;
}
