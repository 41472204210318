@charset "UTF-8";

html, body {
  font-weight: 300;
  font-variation-settings: "wght" 300, "slnt" 0;
}
.content {
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: 0;
  margin-left: 10px;
}
@media (min-width: 768px) {
  .content {
    margin-top: 0;
    margin-right: 25px;
    margin-bottom: 0;
    margin-left: 25px;
  }
}
@media (min-width: 1024px) {
  .content {
    margin-top: 0;
    margin-right: 250px;
    margin-bottom: 0;
    margin-left: 250px;
  }
}