.break--new-system__image,
.break--rooted-in-the-future__image {
  -o-object-fit: fill;
  object-fit: fill;
}

.ss {
  --scroll-height: 500vh;
  height: var(--scroll-height);
  backface-visibility: hidden;
}

.ss__inner {
  height: var(--visible-viewport-height);
  perspective: var(--global-perspective);
  backface-visibility: hidden;
}

.ss__tunnel {
  transform-style: preserve-3d;
  transform: var(--tunnel-translate);
}

.ss__tunnel-plane {
  transform-style: preserve-3d;
  transform: translateZ(var(--offset));
}

.ss__tunnel-wall {
  background-size: 100% 100%;
  background-position: 0 0;
}

.ss--first .ss__tunnel-wall {
  background-image: url("../../images/TunnelGrid-1.svg");
}

.ss--second .ss__tunnel-wall {
  background-image: url("../../images/TunnelGrid-2.svg");
}

.ss__tunnel-wall--top {
  height: var(--tunnel-length);
  transform-origin: center top;
  transform: rotateX(-90deg);
}

.ss--first .ss__tunnel-wall--top {
  background-image: url("../../images/TunnelGrid-1-Top.svg");
}

.ss--second .ss__tunnel-wall--top {
  background-image: url("../../images/TunnelGrid-2-Top.svg");
}

.ss__tunnel-wall--right {
  width: var(--tunnel-length);
  transform-origin: center right;
  transform: rotateY(-90deg);
}

.ss--first .ss__tunnel-wall--right {
  background-image: url("../../images/TunnelGrid-1-Right.svg");
}

.ss--second .ss__tunnel-wall--right {
  background-image: url("../../images/TunnelGrid-2-Right.svg");
}

.ss__tunnel-wall--bottom {
  height: var(--tunnel-length);
  transform-origin: center bottom;
  transform: rotateX(90deg);
}

.ss--first .ss__tunnel-wall--bottom {
  background-image: url("../../images/TunnelGrid-1-Bottom.svg");
}

.ss--second .ss__tunnel-wall--bottom {
  background-image: url("../../images/TunnelGrid-2-Bottom.svg");
}

.ss__tunnel-wall--left {
  width: var(--tunnel-length);
  transform-origin: center left;
  transform: rotateY(90deg);
}

.ss--first .ss__tunnel-wall--left {
  background-image: url("../../images/TunnelGrid-1-Left.svg");
}

.ss--second .ss__tunnel-wall--left {
  background-image: url("../../images/TunnelGrid-2-Left.svg");
}

.ss__tunnel-block {
  transform-style: preserve-3d;
}

.ss__tunnel-block:before {
  content: "";
  width: 100%;
  height: 50px;
  @apply absolute block bg-purple border-solid border-2 border-purple;
}

.ss__tunnel-block--top:before {
  width: 100%;
  height: 100px;
  transform-origin: bottom;
  transform: rotateX(90deg);
  bottom: 0;
  left: 0;
}

.ss__tunnel-block--bottom:before {
  width: 100%;
  height: 100px;
  transform-origin: top;
  transform: rotateX(-90deg);
  top: 0;
  left: 0;
}

.ss__tunnel-block--right:before {
  width: 100px;
  height: 100%;
  transform-origin: left;
  transform: rotateY(90deg);
  left: 0;
  top: 0;
}

.ss__tunnel-block--left:before {
  width: 100px;
  height: 100%;
  transform-origin: right;
  transform: rotateY(-90deg);
  right: 0;
  top: 0;
}

.ss__tunnel-block:after {
  content: "";
  @apply absolute w-full h-full block bottom-0 left-0 border-solid border-2 border-purple;
}

.ss__new-system span {
  font-variation-settings: "wght" 125, "slnt" var(--slnt);
}
