@charset "UTF-8";

.specimen-ot-alt-indicator-inactive {
  @apply text-blue -ml-[1.55em] translate-y-[16px] transition-transform;
}

.specimen-ot-alt-indicator-active {
  @apply text-green inline translate-y-0 transition-transform;
}

@media (hover: hover) {
  .specimen-item:hover {
    @apply cursor-pointer;
  }
}

@media (hover: hover) {
  .specimen-item:hover .specimen-ot-alt-indicator-active {
    @apply -translate-y-[16px] transition-transform;
  }
}

@media (hover: none) {
  .specimen-item:active .specimen-ot-alt-indicator-active {
    @apply -translate-y-[16px] transition-transform;
  }
}

@media (hover: hover) {
  .specimen-item:hover .specimen-ot-alt-indicator-inactive {
    @apply translate-y-0 transition-transform;
  }
}

@media (hover: none) {
  .specimen-item:active .specimen-ot-alt-indicator-inactive {
    @apply translate-y-0 transition-transform;
  }
}

.specimen-item .ot-flat-alternates {
  -webkit-font-feature-settings: "ss04" 1, "kern" 1;
  font-feature-settings: "ss04" 1, "kern" 1;
}

@media (hover: hover) {
  .specimen-item:hover .ot-flat-alternates {
    -webkit-font-feature-settings: "ss04" 0, "kern" 1;
    font-feature-settings: "ss04" 0, "kern" 1;
  }
}

@media (hover: none) {
  .specimen-item:active .ot-flat-alternates {
    -webkit-font-feature-settings: "ss04" 0, "kern" 1;
    font-feature-settings: "ss04" 0, "kern" 1;
  }
}



.specimen-item .ot-flat-alternates-plus-curvedalts {
  -webkit-font-feature-settings: "ss03" 1, "ss04" 0, "kern" 1;
  font-feature-settings: "ss03" 1, "ss04" 0, "kern" 1;
}

@media (hover: hover) {
  .specimen-item:hover .ot-flat-alternates-plus-curvedalts {
    -webkit-font-feature-settings: "ss03" 0, "ss04" 1, "kern" 1;
    font-feature-settings: "ss03" 0, "ss04" 1, "kern" 1;
  }
}

@media (hover: none) {
  .specimen-item:active .ot-flat-alternates-plus-curvedalts {
    -webkit-font-feature-settings: "ss03" 0, "ss04" 1, "kern" 1;
    font-feature-settings: "ss03" 0, "ss04" 1, "kern" 1;
  }
}



.specimen-item .ot-singlestory-plus-curvedalts {
  -webkit-font-feature-settings: "ss02" 1, "ss03" 1, "ss01" 1, "kern" 1;
  font-feature-settings: "ss02" 1, "ss03" 1, "ss01" 1, "kern" 1;
}

@media (hover: hover) {
  .specimen-item:hover .ot-singlestory-plus-curvedalts {
    -webkit-font-feature-settings: "ss02" 0, "ss03" 0, "ss01" 0, "kern" 1;
    font-feature-settings: "ss02" 0, "ss03" 0, "ss01" 0, "kern" 1;
  }
}

@media (hover: none) {
  .specimen-item:active .ot-singlestory-plus-curvedalts {
    -webkit-font-feature-settings: "ss02" 0, "ss03" 0, "ss01" 0, "kern" 1;
    font-feature-settings: "ss02" 0, "ss03" 0, "ss01" 0, "kern" 1;
  }
}

.specimen-item .ot-singlestory {
  -webkit-font-feature-settings: "ss01" 1, "ss02" 1, "kern" 1;
  font-feature-settings: "ss01" 1, "ss02" 1, "kern" 1;
}

@media (hover: hover) {
  .specimen-item:hover .ot-singlestory {
    -webkit-font-feature-settings: "ss01" 0, "ss02" 0, "kern" 1;
    font-feature-settings: "ss01" 0, "ss02" 0, "kern" 1;
  }
}

@media (hover: none) {
  .specimen-item:active .ot-singlestory {
    -webkit-font-feature-settings: "ss01" 0, "ss02" 0, "kern" 1;
    font-feature-settings: "ss01" 0, "ss02" 0, "kern" 1;
  }
}

.specimen-item .letter-with-alt {
  @apply text-green;
}

@media (hover: hover) {
  .specimen-item:hover .letter-with-alt {
    @apply text-blue;
  }
}

@media (hover: none) {
  .specimen-item:active .letter-with-alt {
    @apply text-blue;
  }
}
