@charset "UTF-8";
@tailwind base;

h1 {
  font-family: "GT Planar", "Comic Sans MS", cursive;
  font-weight: 500;
  font-variation-settings: "wght" 500, "slnt" 0;
  font-size: 52px;
  line-height: 60px;
}

h2 {
  font-family: "GT Planar", "Comic Sans MS", cursive;
  font-weight: 500;
  font-variation-settings: "wght" 500, "slnt" 0;
  font-size: 22px;
  line-height: 32px;
}

h3 {
  font-family: "GT Planar", "Comic Sans MS", cursive;
  font-weight: 400;
  font-variation-settings: "wght" 400, "slnt" 0;
  font-size: 24px;
  line-height: 26px;
}

h4 {
  font-family: "GT Planar", "Comic Sans MS", cursive;
  font-size: 44px;
  line-height: 48px;
}

h5 {
  font-family: "GT Planar", "Comic Sans MS", cursive;
  font-size: 34px;
  line-height: 41px;
}

h6 {
  font-family: "GT Planar", "Comic Sans MS", cursive;
  font-size: 24px;
  line-height: 35.52px;
}

h7 {
  font-family: "GT Planar", "Comic Sans MS", cursive;
  font-size: 18px;
  line-height: 21.6px;
} 


/* Text Styles */

@layer base {
  .caps {
    @apply uppercase tracking-[0.04em];
  }
}

.text-italic {
  font-variation-settings: "wght" 300, "slnt" 15;
}

em {
  @apply font-normal text-italic;
}

/* OpenType features */

.ot-onum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
          font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
}

.ot-onum-tnum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
          font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
}

.ot-lnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
          font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
}

.ot-lnum-tnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
          font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
}

.ot-dlig {
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
          font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
}

.ot-sups {
  text-transform: lowercase;
  -webkit-font-feature-settings: "kern" 1, "sups" 1;
          font-feature-settings: "kern" 1, "sups" 1;
}

.ot-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
          font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
}

.ot-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
          font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
}

.ot-tf-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
          font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
}

.ot-tf-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
          font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
}

.ot-osf {
  -webkit-font-feature-settings: "onum" 1, "kern" 1;
          font-feature-settings: "onum" 1, "kern" 1;
}

.ot-ssplusarrow {
  -webkit-font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1;
          font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1;
}

.ot-salt {
  -webkit-font-feature-settings: "calt" 1, "kern" 1;
          font-feature-settings: "calt" 1, "kern" 1;
}

.ot-smcp {
  -webkit-font-feature-settings: "smcp" 1, "kern" 1;
          font-feature-settings: "smcp" 1, "kern" 1;
}

.ot-case {
  -webkit-font-feature-settings: "case" 1, "kern" 1;
          font-feature-settings: "case" 1, "kern" 1;
}

.ot-ss01 {
  -webkit-font-feature-settings: "ss01" 1, "kern" 1;
          font-feature-settings: "ss01" 1, "kern" 1;
}

.ot-ss02 {
  -webkit-font-feature-settings: "ss02" 1, "kern" 1;
          font-feature-settings: "ss02" 1, "kern" 1;
}

.ot-ss03 {
  -webkit-font-feature-settings: "ss03" 1, "kern" 1;
          font-feature-settings: "ss03" 1, "kern" 1;
}

.ot-ss04 {
  -webkit-font-feature-settings: "ss04" 1, "kern" 1;
          font-feature-settings: "ss04" 1, "kern" 1;
}

.ot-ss05 {
  -webkit-font-feature-settings: "ss05" 1, "kern" 1;
          font-feature-settings: "ss05" 1, "kern" 1;
}

.ot-ss04-ss05 {
  -webkit-font-feature-settings: "ss04" 1, "ss05" 1, "kern" 1;
          font-feature-settings: "ss04" 1, "ss05" 1, "kern" 1;
}

.ot-aalt {
  -webkit-font-feature-settings: "aalt" 1, "kern" 1;
          font-feature-settings: "aalt" 1, "kern" 1;
}