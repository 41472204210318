.axes-overview {
  --visible-viewport-height: 100vh;
  --wght: 450;
  --slnt: 0;
  --pcx: 50%;
  --pcy: 50%;
  --slnt-text: "0°";
  --wght-text: "0";
  --slnt-color: "green";
  --wght-color: "green";
}

.axes-overview__type {
  font-variation-settings: "wght" var(--wght), "slnt" var(--slnt);
}

.axes-overview__puck {
  font-variation-settings: "wght" 300, "slnt" 0;
}

.axes-overview__puck--top {
  transform: translateX(var(--pcx));
}

.axes-overview__puck--left {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: translateY(var(--pcy));
}

.axes-overview__puck-inner {
  will-change: transform;
  transform: translateY(calc(var(--pcy) * -1)) rotate(180deg);
}

.axes-overview__puck-inner--top {
  transform: translateX(calc(var(--pcx) * -1));
}

.axes-overview__puck-inner--top:after {
  content: var(--slnt-text);
  color: var(--slnt-color);
}

.axes-overview__puck-inner--left:after {
  content: var(--wght-text);
  color: var(--wght-color);
}
