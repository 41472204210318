@charset "UTF-8";

.js-animation-paused,
.js-animation-paused * {
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

.light-all-italic-angles {
  animation: animation-light-all-italic-angles 1.5s infinite alternate ease-in-out;
}

@keyframes animation-light-all-italic-angles {
  0% {
    font-variation-settings: "wght" 300, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 300, "slnt" 45;
  }
}

.medium-all-italic-angles {
  animation: animation-medium-all-italic-angles 1.5s infinite alternate ease-in-out;
}

@keyframes animation-medium-all-italic-angles {
  0% {
    font-variation-settings: "wght" 500, "slnt" -45;
  }
  100% {
    font-variation-settings: "wght" 500, "slnt" 45;
  }
}

/* HUD ANIMATIONS */

@keyframes hud-snr-plus1 {
  0% {
    transform: translate(0,-57.2px);
    opacity: 1;
  }
  30% {
    transform: translate(-40px,51px);
    opacity: 1;
  }
  30.01% {
    transform: translate(-40px,51px);
    opacity: 0;
  }
  30.02% {
    transform: translate(0,-57.2px);
    opacity: 0;
  }
  100% {
    transform: translate(0,-57.2px);
    opacity: 0;
  }
}

.animation-snr-plus1 {
  animation: hud-snr-plus1 60s infinite steps(25);
  transform: translate(0,-57.2px);
  opacity: 0;
  animation-delay: 0.1s;
}

@keyframes hud-snr-plus2 {
  0% {
    transform: translate(0,-65.2px);
    opacity: 1;
  }
  40% {
    transform: translate(20px,42px);
    opacity: 1;
  }
  40.01% {
    transform: translate(20px,42px);
    opacity: 0;
  }
  40.02% {
    transform: translate(0,-65.2px);
    opacity: 0;
  }
  100% {
    transform: translate(0,-65.2px);
    opacity: 0;
  }
}

.animation-snr-plus2 {
  animation: hud-snr-plus2 40s infinite steps(35);
  animation-delay: 10s;
  transform: translate(0,-65.2px);
  opacity: 0;
}

@keyframes hud-snr-plus3 {
  0% {
    transform: translate(30px,-23px);
    opacity: 1;
  }
  40% {
    transform: translate(20px,55px);
    opacity: 1;
  }
  40.01% {
    transform: translate(20px,55px);
    opacity: 0;
  }
  40.02% {
    transform: translate(30px,-23px);
    opacity: 0;
  }
  100% {
    transform: translate(30px,-23px);
    opacity: 0;
  }
}

.animation-snr-plus3 {
  animation: hud-snr-plus3 30s infinite steps(15);
  animation-delay: 16s;
  transform: translate(20px,55px);
  opacity: 0;
}

@keyframes hud-snr-plus4 {
  0% {
    transform: translate(-40px,-24px);
    opacity: 1;
  }
  30% {
    transform: translate(-60px,86px);
    opacity: 1;
  }
  30.01% {
    transform: translate(-60px,86px);
    opacity: 0;
  }
  30.02% {
    transform: translate(-40px,-24px);
    opacity: 0;
  }
  100% {
    transform: translate(-40px,-24px);
    opacity: 0;
  }
}

.animation-snr-plus4 {
  animation: hud-snr-plus4 50s infinite steps(30);
  animation-delay: 5s;
  transform: translate(-40px,-24px);
  opacity: 0;
}

@keyframes hud-snr-plus5 {
  0% {
    transform: translate(-73.5px,-14px);
    opacity: 1;
  }
  30% {
    transform: translate(9px,13px);
    opacity: 1;
  }
  30.01% {
    transform: translate(9px,13px);
    opacity: 0;
  }
  30.02% {
    transform: translate(-73.5px,-14px);
    opacity: 0;
  }
  100% {
    transform: translate(-73.5px,-14px);
    opacity: 0;
  }
}

.animation-snr-plus5 {
  animation: hud-snr-plus5 80s infinite steps(30);
  animation-delay: 7.5s;
  transform: translate(-73.5px,-14px);
  opacity: 0;
}

.anim-pilot-stat {
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(8);
  animation-direction: alternate;
}

.anim-pilot-stat-focus {
  animation-name: pilot-stat-focus;
}

@keyframes pilot-stat-focus {
  0% {
    @apply w-[60%];
  }
  15% {
    @apply w-[20%];
  }
  30% {
    @apply w-[30%];
  }
  80% {
    @apply w-[80%];
  }
  100% {
    @apply w-[60%];
  }
}

.anim-pilot-stat-oxygen {
  animation-name: pilot-stat-oxygen;
}

@keyframes pilot-stat-oxygen {
  0% {
    @apply w-[90%];
  }
  20% {
    @apply w-[70%];
  }
  40% {
    @apply w-[100%];
  }
  70% {
    @apply w-[80%];
  }
  100% {
    @apply w-[90%];
  }
}

.anim-pilot-stat-awake {
  animation-name: pilot-stat-awake;
}

@keyframes pilot-stat-awake {
  0% {
    @apply w-[80%];
  }
  10% {
    @apply w-[60%];
  }
  50% {
    @apply w-[50%];
  }
  60% {
    @apply w-[90%];
  }
  100% {
    @apply w-[80%];
  }
}

.anim-pilot-stat-curiosity {
  animation-name: pilot-stat-curiosity;
}

@keyframes pilot-stat-curiosity {
  0% {
    @apply w-[70%];
  }
  20% {
    @apply w-[50%];
  }
  55% {
    @apply w-[60%];
  }
  70% {
    @apply w-[100%];
  }
  100% {
    @apply w-[70%];
  }
}

.anim-pilot-stat-ability {
  animation-name: pilot-stat-ability;
}

@keyframes pilot-stat-ability {
  0% {
    @apply w-[80%];
  }
  10% {
    @apply w-[90%];
  }
  50% {
    @apply w-[30%];
  }
  80% {
    @apply w-[100%];
  }
  100% {
    @apply w-[80%];
  }
}


.anim-pilot-stat-chill {
  animation-name: pilot-stat-chill;
}

@keyframes pilot-stat-chill {
  0% {
    @apply w-[30%];
  }
  30% {
    @apply w-[70%];
  }
  50% {
    @apply w-[60%];
  }
  75% {
    @apply w-[90%];
  }
  100% {
    @apply w-[30%];
  }
}



@keyframes animation-mod-viz-candles {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

.animation-hud-bp-wheel {
  animation-name: hud-bp-wheel-left;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform: rotate(0deg);
  animation-timing-function: linear;
}

.animation-hud-bp-wheel-left {
  transform-origin: 41px 41px;
}

.animation-hud-bp-wheel-right {
  transform-origin: 135px 41px;
}

@keyframes hud-bp-wheel-left {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animation-hud-r-wheel {
  animation-name: hud-r-wheel;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform: rotate(0deg);
  animation-timing-function: linear;
  transform-origin: 39px 37px;
}

@keyframes hud-r-wheel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.minisite-link {
  font-variation-settings: "wght" 300, "slnt" 0;
  transition: font-variation-settings 0.2s ease-in;
  will-change: font-variation-settings;
}

.minisite-link:nth-of-type(3n):hover {
  font-variation-settings: "wght" 700, "slnt" 45;
  transition: font-variation-settings 0.2s ease-out;
}

.minisite-link:nth-of-type(3n+1):hover {
  font-variation-settings: "wght" 700, "slnt" -45;
  transition: font-variation-settings 0.2s ease-out;
}

.minisite-link:nth-of-type(3n+2):hover {
  font-variation-settings: "wght" 700, "slnt" 0;
  transition: font-variation-settings 0.2s ease-out;
}





