@charset "UTF-8";

/* --- FULL FAMILY VAR FONTS ––– */

@font-face {
  font-family: "GT Planar";
  src: url("../../fonts/GT-Planar-VF.woff2") format("woff2-variations"),
    url("../../fonts/GT-Planar-VF.ttf") format("truetype-variations");
  font-weight: normal;
}

.font-thin-neg45 {
  font-variation-settings: "wght" 100, "slnt" -45;
}

.font-thin-neg30 {
  font-variation-settings: "wght" 100, "slnt" -30;
}

.font-thin-neg15 {
  font-variation-settings: "wght" 100, "slnt" -15;
}

.font-thin-0 {
  font-variation-settings: "wght" 100, "slnt" 0;
}

.font-thin-pos15 {
  font-variation-settings: "wght" 100, "slnt" 15;
}

.font-thin-pos30 {
  font-variation-settings: "wght" 100, "slnt" 30;
}

.font-thin-pos45 {
  font-variation-settings: "wght" 100, "slnt" 45;
}

.font-light-neg45 {
  font-variation-settings: "wght" 300, "slnt" -45;
}

.font-light-neg30 {
  font-variation-settings: "wght" 300, "slnt" -30;
}

.font-light-neg15 {
  font-variation-settings: "wght" 300, "slnt" -15;
}

.font-light-0 {
  font-variation-settings: "wght" 300, "slnt" 0;
}

.font-light-pos15 {
  font-variation-settings: "wght" 300, "slnt" 15;
}

.font-light-pos30 {
  font-variation-settings: "wght" 300, "slnt" 30;
}

.font-light-pos45 {
  font-variation-settings: "wght" 300, "slnt" 45;
}

.font-regular-neg45 {
  font-variation-settings: "wght" 400, "slnt" -45;
}

.font-regular-neg30 {
  font-variation-settings: "wght" 400, "slnt" -30;
}

.font-regular-neg15 {
  font-variation-settings: "wght" 400, "slnt" -15;
}

.font-regular-0 {
  font-variation-settings: "wght" 400, "slnt" 0;
}

.font-regular-pos15 {
  font-variation-settings: "wght" 400, "slnt" 15;
}

.font-regular-pos30 {
  font-variation-settings: "wght" 400, "slnt" 30;
}

.font-regular-pos45 {
  font-variation-settings: "wght" 400, "slnt" 45;
}

.font-medium-neg45 {
  font-variation-settings: "wght" 500, "slnt" -45;
}

.font-medium-neg30 {
  font-variation-settings: "wght" 500, "slnt" -30;
}

.font-medium-neg15 {
  font-variation-settings: "wght" 500, "slnt" -15;
}

.font-medium-0 {
  font-variation-settings: "wght" 500, "slnt" 0;
}

.font-medium-pos15 {
  font-variation-settings: "wght" 500, "slnt" 15;
}

.font-medium-pos30 {
  font-variation-settings: "wght" 500, "slnt" 30;
}

.font-medium-pos45 {
  font-variation-settings: "wght" 500, "slnt" 45;
}

.font-bold-neg45 {
  font-variation-settings: "wght" 700, "slnt" -45;
}

.font-bold-neg30 {
  font-variation-settings: "wght" 700, "slnt" -30;
}

.font-bold-neg15 {
  font-variation-settings: "wght" 700, "slnt" -15;
}

.font-bold-0 {
  font-variation-settings: "wght" 700, "slnt" 0;
}

.font-bold-pos15 {
  font-variation-settings: "wght" 700, "slnt" 15;
}

.font-bold-pos30 {
  font-variation-settings: "wght" 700, "slnt" 30;
}

.font-bold-pos45 {
  font-variation-settings: "wght" 700, "slnt" 45;
}

.font-black-neg45 {
  font-variation-settings: "wght" 900, "slnt" -45;
}

.font-black-neg30 {
  font-variation-settings: "wght" 900, "slnt" -30;
}

.font-black-neg15 {
  font-variation-settings: "wght" 900, "slnt" -15;
}

.font-black-0 {
  font-variation-settings: "wght" 900, "slnt" 0;
}

.font-black-pos15 {
  font-variation-settings: "wght" 900, "slnt" 15;
}

.font-black-pos30 {
  font-variation-settings: "wght" 900, "slnt" 30;
}

.font-black-pos45 {
  font-variation-settings: "wght" 900, "slnt" 45;
}
