@tailwind base;

.intro {
  height: var(--visible-viewport-height);

  background: black;
  overflow: hidden;
  @apply select-none;
}

.intro__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.intro__text-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: var(--global-perspective);
  overflow: hidden;
}

.intro__plane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  will-change: transform;
}

.intro__plane--first {
  transform: translateZ(-10000px);
  opacity: 0;
}

.intro__plane--final {
  transform: translateZ(250px);
}

.intro--enter .intro__plane--first {
  transition: transform 2.5s ease-out, opacity 0.75s ease-out;
  transform: translateZ(250px);
  opacity: 1;
}

.intro__plane:nth-child(1) {
  transition-delay: 0s;
}

.intro__plane:nth-child(2) {
  transition-delay: 1.25s;
}

.intro__plane:nth-child(3) {
  transition-delay: 2.5s;
}

.intro--enter-final .intro__plane--final {
  transition: transform 3.5s cubic-bezier(0, 0.89, 0.6, 1);
  transform: translateZ(0);
}

.intro__plane > div {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  /* font-size: 10vw; */
  line-height: 0.9em;
  letter-spacing: -0.05em;
  color: #fff;

  @apply text-[14.75vw] md:text-4xl;
}

.intro__plane:nth-child(1) div {
  top: 0;
  @apply text-yellow;
}

.intro__plane:nth-child(2) div {
  bottom: 0;
  @apply text-yellow;
}

.intro__plane:nth-child(3) div {
  top: 0;
  @apply text-yellow;
}

.intro__title-line {
  display: block;
}

.intro__title-space {
  width: 1em;
  display: inline-block;
}

.intro__scroll-down {
  position: absolute;
  bottom: 1.5rem;
  line-height: 0.9em;
  letter-spacing: -0.01em;
  font-variation-settings: "wght" 700, "slnt" 0;
  color: greenyellow;
  text-align: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: all 2s ease-out;
  opacity: 0;
  transform: translateY(-150%);
}

.intro__scroll-down--visible {
  opacity: 1;
  transform: translateY(0);
}
