@charset "UTF-8";
@tailwind base;

.family-overview-table {
  table-layout: fixed;
  white-space: fixed;
  width: calc(100vw - 20px);
  @apply select-none;
}

@media only screen and (min-width: 768px) {
  .family-overview-table {
    width: calc(100vw - 50px);
  }
}

@media only screen and (min-width: 1024px) {
  .family-overview-table {
    width: calc(100vw - 500px);
  }
}

/* 
.family-overview-table tr.ov__thin td:nth-child(2) {
  font-variation-settings: "wght" 100, "slnt" -45;
}

.family-overview-table tr.ov__thin td:nth-child(3) {
  font-variation-settings: "wght" 100, "slnt" -30;
}

.family-overview-table tr.ov__thin td:nth-child(4) {
  font-variation-settings: "wght" 100, "slnt" -15;
}

.family-overview-table tr.ov__thin td:nth-child(5) {
  font-variation-settings: "wght" 100, "slnt" 0;
}

.family-overview-table tr.ov__thin td:nth-child(6) {
  font-variation-settings: "wght" 100, "slnt" 15;
}

.family-overview-table tr.ov__thin td:nth-child(7) {
  font-variation-settings: "wght" 100, "slnt" 30;
}

.family-overview-table tr.ov__thin td:nth-child(8) {
  font-variation-settings: "wght" 100, "slnt" 45;
}

.family-overview-table tr.ov__light td:nth-child(2) {
  font-variation-settings: "wght" 300, "slnt" -45;
}

.family-overview-table tr.ov__light td:nth-child(3) {
  font-variation-settings: "wght" 300, "slnt" -30;
}

.family-overview-table tr.ov__light td:nth-child(4) {
  font-variation-settings: "wght" 300, "slnt" -15;
}

.family-overview-table tr.ov__light td:nth-child(5) {
  font-variation-settings: "wght" 300, "slnt" 0;
}

.family-overview-table tr.ov__light td:nth-child(6) {
  font-variation-settings: "wght" 300, "slnt" 15;
}

.family-overview-table tr.ov__light td:nth-child(7) {
  font-variation-settings: "wght" 300, "slnt" 30;
}

.family-overview-table tr.ov__light td:nth-child(8) {
  font-variation-settings: "wght" 300, "slnt" 45;
}

.family-overview-table tr.ov__regular td:nth-child(2) {
  font-variation-settings: "wght" 400, "slnt" -45;
}

.family-overview-table tr.ov__regular td:nth-child(3) {
  font-variation-settings: "wght" 400, "slnt" -30;
}

.family-overview-table tr.ov__regular td:nth-child(4) {
  font-variation-settings: "wght" 400, "slnt" -15;
}

.family-overview-table tr.ov__regular td:nth-child(5) {
  font-variation-settings: "wght" 400, "slnt" 0;
}

.family-overview-table tr.ov__regular td:nth-child(6) {
  font-variation-settings: "wght" 400, "slnt" 15;
}

.family-overview-table tr.ov__regular td:nth-child(7) {
  font-variation-settings: "wght" 400, "slnt" 30;
}

.family-overview-table tr.ov__regular td:nth-child(8) {
  font-variation-settings: "wght" 400, "slnt" 45;
}

.family-overview-table tr.ov__medium td:nth-child(2) {
  font-variation-settings: "wght" 500, "slnt" -45;
}

.family-overview-table tr.ov__medium td:nth-child(3) {
  font-variation-settings: "wght" 500, "slnt" -30;
}

.family-overview-table tr.ov__medium td:nth-child(4) {
  font-variation-settings: "wght" 500, "slnt" -15;
}

.family-overview-table tr.ov__medium td:nth-child(5) {
  font-variation-settings: "wght" 500, "slnt" 0;
}

.family-overview-table tr.ov__medium td:nth-child(6) {
  font-variation-settings: "wght" 500, "slnt" 15;
}

.family-overview-table tr.ov__medium td:nth-child(7) {
  font-variation-settings: "wght" 500, "slnt" 30;
}

.family-overview-table tr.ov__medium td:nth-child(8) {
  font-variation-settings: "wght" 500, "slnt" 45;
}

.family-overview-table tr.ov__bold td:nth-child(2) {
  font-variation-settings: "wght" 700, "slnt" -45;
}

.family-overview-table tr.ov__bold td:nth-child(3) {
  font-variation-settings: "wght" 700, "slnt" -30;
}

.family-overview-table tr.ov__bold td:nth-child(4) {
  font-variation-settings: "wght" 700, "slnt" -15;
}

.family-overview-table tr.ov__bold td:nth-child(5) {
  font-variation-settings: "wght" 700, "slnt" 0;
}

.family-overview-table tr.ov__bold td:nth-child(6) {
  font-variation-settings: "wght" 700, "slnt" 15;
}

.family-overview-table tr.ov__bold td:nth-child(7) {
  font-variation-settings: "wght" 700, "slnt" 30;
}

.family-overview-table tr.ov__bold td:nth-child(8) {
  font-variation-settings: "wght" 700, "slnt" 45;
}

.family-overview-table tr.ov__black td:nth-child(2) {
  font-variation-settings: "wght" 900, "slnt" -45;
}

.family-overview-table tr.ov__black td:nth-child(3) {
  font-variation-settings: "wght" 900, "slnt" -30;
}

.family-overview-table tr.ov__black td:nth-child(4) {
  font-variation-settings: "wght" 900, "slnt" -15;
}

.family-overview-table tr.ov__black td:nth-child(5) {
  font-variation-settings: "wght" 900, "slnt" 0;
}

.family-overview-table tr.ov__black td:nth-child(6) {
  font-variation-settings: "wght" 900, "slnt" 15;
}

.family-overview-table tr.ov__black td:nth-child(7) {
  font-variation-settings: "wght" 900, "slnt" 30;
}

.family-overview-table tr.ov__black td:nth-child(8) {
  font-variation-settings: "wght" 900, "slnt" 45;
} */

/* .js-family-overview-cell-hover {
  
}

.ov__mono .js-family-overview-cell-hover {
} */

th,
td {
  @apply border border-white;
}

th {
  @apply text-sm leading-5;
}

th div {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  min-height: 6em;
}

@media only screen and (min-width: 1024px) {
  th div {
    min-height: 7em;
  }
}

td {
  @apply text-[8vw] md:text-[4.5vw] lg:text-[4vw] text-center;
  font-variation-settings: "slnt" 200, "wght" 800;
}

.hide-on-mobile {
  display: none;
}

@media only screen and (min-width: 1100px) {
  .hide-on-mobile {
    display: inline;
  }
}

.empty-cell {
  border-top: 0px solid black;
  border-left: 0px solid black;
}

.family-overview-table {
  --font-variation-settings: "slnt" 0, "wght" 400;
  --color: red;
}

.family-overview-table td {
  font-variation-settings: "slnt" 0, "wght" 100;
  will-change: font-variation-settings, color;
}

tr:nth-of-type(2) {
  --wght: 100;
}

tr:nth-of-type(3) {
  --wght: 200;
}

tr:nth-of-type(4) {
  --wght: 300;
}

tr:nth-of-type(5) {
  --wght: 400;
}

tr:nth-of-type(6) {
  --wght: 650;
}

tr:nth-of-type(7) {
  --wght: 900;
}

td:nth-of-type(1) {
  font-variation-settings: "slnt" -45, "wght" var(--wght);
}

td:nth-of-type(2) {
  font-variation-settings: "slnt" -30, "wght" var(--wght);
}

td:nth-of-type(3) {
  font-variation-settings: "slnt" -15, "wght" var(--wght);
}

td:nth-of-type(4) {
  font-variation-settings: "slnt" 0, "wght" var(--wght);
}

td:nth-of-type(5) {
  font-variation-settings: "slnt" 15, "wght" var(--wght);
}

td:nth-of-type(6) {
  font-variation-settings: "slnt" 30, "wght" var(--wght);
}

td:nth-of-type(7) {
  font-variation-settings: "slnt" 45, "wght" var(--wght);
}


table.active td {
  font-variation-settings: var(--font-variation-settings);
  color: var(--color);
}
