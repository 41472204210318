@charset "UTF-8";
@tailwind base;

.hud-overlay-container {
  /* This is set to 180% as the user scrolls past intro */
  --hud-inactive-scale: 1;
  will-change: transform;
  transition: transform 1s ease-in-out;
  transform: scale3d(
    var(--hud-inactive-scale),
    var(--hud-inactive-scale),
    var(--hud-inactive-scale)
  );
  pointer-events: none;
  @apply select-none;
}

.hud-overlay-container.disable-transition {
  transition: none;
}

/* Together with the pointer-events: none; above this should make the page below the HUD interactive to mouse events again. No idea what it does, my friend Mihai gave me this code bit :D */

.hud-overlay-container > div {
  pointer-events: initial;
}

.hud-overlay-active .hud-overlay-container {
  transition: transform 0.2s ease-in-out;
  transform: scale3d(1, 1, 1);
}

.hud-element-container {
  @apply bg-black border-2 border-gray-dk;
}

/* HUD Status & Nav Dots */

.hud-status-dot {
  @apply inline-block w-3 h-3 mb-[2px] lg:mb-[4px] lg:mt-[5px] rounded-full;
}

.hud-nav-dot {
  @apply block w-3 h-3 md:w-4 md:h-4 md:mb-[4px] rounded-full;
}

.hud-dot__inactive {
  @apply bg-green-md;
}

.hud-dot__active {
  @apply bg-green;
}

.hud-dot__fail {
  @apply bg-red;
}

.hud-status-area .hud-status-dot {
  @apply lg:mr-[6px];
}

/* BATTERY */

.battery-status {
  @apply pl-2 pr-2;
}

.battery-empty {
  background-image: url("../../images/hud/bat-empty.png");
  background-repeat: space;
}

.battery-full {
  background-image: url("../../images/hud/bat-full.png");
  background-repeat: space;
}

/* SPEEDOMETER */

.speedometer-counter {
  font-size: 26px;
  letter-spacing: 0.15em;
  text-align: center;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  color: black;
  font-variation-settings: "wght" 700, "slnt" 0;
}

.speedometer-counter::before {
  content: attr(data-outline-duplicate-text);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  text-align: center;
  -webkit-text-stroke: 2px #00ff85;
  font-variation-settings: "wght" 700, "slnt" 0;
}

.speedometer-dial {
  --rotation: -45deg;
  transform-origin: 74px 108px;
  transform: rotate(var(--rotation));
  transition: transform 0.2s ease;
  will-change: transform;
}

/* VECTOR */

.vector-full {
  background-image: url("../../images/hud/bat-full.png");
  background-repeat: space;
}

.vec-desktop-bg {
  background-image: url("../../images/hud/vec-desktop-bg.png");
  background-repeat: repeat;
  background-size: 8%;
}

/* PILOT STATS */

.pilot-stat-empty {
  background-image: url("../../images/hud/stats-empty.gif");
  background-repeat: repeat-x;
}

.pilot-stat-full {
  background-image: url("../../images/hud/stats-full.gif");
  background-repeat: repeat-x;
}

.pilot-portrait {
  @apply relative block w-[6em];
}

.pilot-portrait-errors {
  @apply absolute w-full h-full z-50 bg-cover;
  background-image: url("../../images/hud/pilot-glitch.gif");
}

.pilot-portrait-colorize {
  @apply absolute w-full h-full z-40 bg-green-md bg-blend-color bg-opacity-10;
}

.pilot-portrait-image {
  @apply absolute w-full h-full z-30 bg-center bg-cover bg-green bg-blend-overlay bg-opacity-50;
}

.pilot-portrait-background {
  @apply absolute w-full h-full z-20 bg-cover;
  background-image: url("../../images/hud/pilot-bg.gif");
}

/* MOD Viz */

.mod-viz {
  @apply relative block h-[66px] w-full;
}

.mod-viz-overlay {
  @apply absolute w-full h-full z-50 bg-cover;
  background-image: url("../../images/hud/mod-overlay.gif");
}

.mod-viz-candles {
  @apply absolute w-full h-full z-40 bg-cover;
  background-image: url("../../images/hud/mod-candles.png");
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: animation-mod-viz-candles 20s linear infinite;
}

.mod-viz-bg {
  @apply absolute w-full h-full z-30 bg-cover;
  background-image: url("../../images/hud/mod-bg.png");
}

/* TLT Indicator */

.hud-tilt-mode-active {
}

.no-touch .hud-tilt-mode-request,
.hud-tilt-mode-active .hud-tilt-mode-request {
  @apply hidden;
}

.hud-tilt-mode-indicator-off {
  @apply text-red;
}

.hud-tilt-mode-indicator-on {
  @apply text-gray-dk;
}

.no-touch .hud-tilt-mode-indicator-off,
.hud-tilt-mode-active .hud-tilt-mode-indicator-off {
  @apply text-gray-dk;
}

.no-touch .hud-tilt-mode-indicator-on,
.hud-tilt-mode-active .hud-tilt-mode-indicator-on {
  @apply text-green;
}

/* Animations and modifications of HUD during intro */
/* .intro-active, .timewarp-active, .scroll-animations-active */

/* HUD Nav Buttons */

.intro-active .hud-nav-buttons {
  @apply hidden !important;
}

/* .hud-nav-button-disabled {
  @apply border-gray-dk !important;
}

.hud-nav-button-disabled div:nth-child(1) {
  @apply bg-gray-dk border-gray-dk !important;
}

.hud-nav-button-disabled div:nth-child(2) {
  @apply text-gray-dk !important;
}

.hud-nav-button-disabled:hover {
  @apply bg-black cursor-not-allowed !important;
} */

/* ANIMATIONS OF HUD DURING INTRO PRE WARPSPEED */

@keyframes animation-loading-hud-bg-blinking {
  0% {
    @apply bg-green-md;
  }
  50% {
    @apply bg-green-dk;
  }
}

@keyframes animation-loading-hud-text-blinking {
  0% {
    @apply text-black;
  }
  50% {
    @apply text-green-dk;
  }
}

@keyframes animation-loading-hud-border-blinking {
  0% {
    @apply border-black;
  }
  50% {
    @apply border-green-dk;
  }
}

@keyframes animation-loading-hud-fill-blinking {
  0% {
    @apply fill-black;
  }
  50% {
    @apply fill-green-dk;
  }
}

@keyframes animation-loading-hud-text-stroke-blinking {
  0% {
    -webkit-text-stroke: 2px #000000;
  }
  50% {
    -webkit-text-stroke: 2px #002c00;
  }
}

@keyframes animation-loading-hud-stroke-blinking {
  0% {
    stroke: #000000;
  }
  50% {
    stroke: #002c00;
  }
}

@keyframes animation-intro-hud-bg-gray-blinking {
  0% {
    @apply bg-red;
  }
  50% {
    @apply bg-gray-dk;
  }
}

@keyframes animation-loading-hud-statusloading-blinking {
  0% {
    @apply bg-black;
  }
  50% {
    @apply bg-green;
  }
}

@keyframes animation-loading-hud-batbg-fill {
  0% {
    @apply bg-gray;
  }
  50% {
    @apply bg-gray-dk;
  }
}

.intro-active.intro-pre-warp .hud-sonar-ellipses {
  /* animation: animation-loading-hud-stroke-blinking 1.5s infinite steps(1); */
  -webkit-text-stroke: #606060;
}

.intro-active.intro-pre-warp .speedometer-counter::before {
  -webkit-text-stroke: #606060;
  /* animation: animation-loading-hud-text-stroke-blinking 1.5s infinite steps(1); */
}

.intro-active.intro-pre-warp div[data-component="hud__vec-puck-track"],
.intro-active.intro-pre-warp div[data-component="hud__vec-px"],
.intro-active.intro-pre-warp div[data-component="hud__tilt-pc"] {
  @apply bg-gray-dk border-gray-dk;
  /* animation: animation-loading-hud-bg-blinking 1.5s infinite steps(1),
    animation-loading-hud-border-blinking 1.5s infinite steps(1); */
}

.intro-active.intro-pre-warp .hud-status-dot {
  @apply bg-gray-dk;
  /* animation: animation-loading-hud-bg-blinking 1.5s infinite steps(1); */
}

.intro-pre-warp [data-component="hud__battery-pc"] {
  @apply text-gray;
  /* animation: animation-loading-hud-text-blinking 1.5s infinite steps(1); */
}

.intro-active.intro-pre-warp .mod-viz {
  @apply border-gray;
}

.intro-active.intro-pre-warp .mod-viz-candles {
  background-image: url("../../images/hud/mod-candles-loading.png");
  animation: animation-mod-viz-candles 10s steps(20) infinite;
}

.scroll-animations-active .mod-viz-candles {
  background-image: url("../../images/hud/mod-candles.png");
  animation: animation-mod-viz-candles 20s linear infinite;
}

.intro-active.intro-pre-warp .mod-viz-overlay {
  opacity: 0;
}

.intro-active.intro-pre-warp .hud-status-area div div .hud-status-dot {
  @apply bg-black;
  animation: animation-loading-hud-statusloading-blinking 6s steps(1);
}

.intro-active.intro-pre-warp .hud-status-area div:nth-of-type(1) div .hud-status-dot {
  animation-delay: 0.25s;
}

.intro-active.intro-pre-warp .hud-status-area div:nth-of-type(2) div .hud-status-dot {
  animation-delay: 0.5s;
}

.intro-active.intro-pre-warp .hud-status-area div:nth-of-type(3) div .hud-status-dot {
  animation-delay: 0.75s;
}

.intro-active.intro-pre-warp .hud-status-area div:nth-of-type(4) div .hud-status-dot {
  animation-delay: 1s;
}

.intro-active.intro-pre-warp .hud-status-area div:nth-of-type(5) div .hud-status-dot {
  animation-delay: 1.25s;
}

.intro-active.intro-pre-warp foreignObject[data-component="hud__tape-counter-count"] {
  @apply bg-gray-dk text-gray-dk !important;
}

.intro-active.intro-pre-warp .animation-hud-bp-wheel, 
.intro-active.intro-pre-warp .animation-hud-r-wheel {
  animation-duration: 8s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green {
  @apply bg-gray-dk;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(1) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(2) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 1.5s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(3) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 1.0s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(4) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 0.5s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(5) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 4.5s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(6) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 0.25s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(7) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 2.75s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(8) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 3.5s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-last-child(9) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 4.7s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(9) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 4.5s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(20) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 1.8s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(17) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 1.2s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(23) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 0.6s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(10) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 4.1s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(8) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 0.4s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(3) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 3.9s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(24) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 3.6s;
}

.intro-active.intro-pre-warp div[data-component=hud__battery-inner] span.bg-green:nth-child(14) {
  animation: animation-loading-hud-batbg-fill 0.2s normal steps(1);
  animation-delay: 0.9s;
}

.intro-active.intro-pre-warp .pilot-portrait-errors {
  background-image: url("../../images/hud/pilot-glitch-loading.gif");
  @apply bg-blend-difference;
}


/* .intro-active .hud-overlay-container:not(.scroll-animations-active) .marquee3k__wrapper {
  animation: animation-loading-hud-text-blinking 1.0s infinite steps(1);
} */

/* ANIMATIONS DURING WARPSPEED */

@keyframes animation-intro-hud-bg-blinking {
  0% {
    @apply bg-red;
  }
  50% {
    @apply bg-red-dk;
  }
}

@keyframes animation-intro-hud-text-blinking {
  0% {
    @apply text-red;
  }
  50% {
    @apply text-red-dk;
  }
}

@keyframes animation-intro-hud-border-blinking {
  0% {
    @apply border-red-md;
  }
  50% {
    @apply border-red;
  }
}

@keyframes animation-intro-hud-fill-blinking {
  0% {
    @apply fill-red-md;
  }
  50% {
    @apply fill-red;
  }
}

@keyframes animation-intro-hud-text-stroke-blinking {
  0% {
    -webkit-text-stroke: 2px #280000;
  }
  50% {
    -webkit-text-stroke: 2px #ff003d;
  }
}

@keyframes animation-intro-hud-stroke-blinking {
  0% {
    stroke: #280000;
  }
  50% {
    stroke: #ff003d;
  }
}

.timewarp-active .animation-snr-plus1,
.timewarp-active .animation-snr-plus2,
.timewarp-active .animation-snr-plus3,
.timewarp-active .animation-snr-plus4,
.timewarp-active .animation-snr-plus5 {
  opacity: 0 !important;
}

.timewarp-active .hud-sonar-ellipses {
  animation: animation-intro-hud-stroke-blinking 0.5s infinite steps(1);
}

.timewarp-active .speedometer-counter::before {
  animation: animation-intro-hud-text-stroke-blinking 0.5s infinite steps(1);
}

.timewarp-active div[data-component="hud__vec-puck-track"],
.timewarp-active div[data-component="hud__vec-px"],
.timewarp-active div[data-component="hud__tilt-pc"] {
  animation: animation-intro-hud-bg-blinking 0.5s infinite steps(1),
    animation-intro-hud-border-blinking 0.5s infinite steps(1);
}

.timewarp-active .mod-viz {
  animation: animation-intro-hud-border-blinking 0.5s infinite steps(1);
}

.timewarp-active .hud-status-dot {
  animation: animation-intro-hud-bg-blinking 0.5s infinite steps(1);
}

.timewarp-active [data-component="hud__battery-pc"] {
  animation: animation-intro-hud-text-blinking 0.5s infinite steps(1);
}

.timewarp-active .marquee3k__wrapper {
  animation: animation-intro-hud-text-blinking 0.5s infinite steps(1);
}

.timewarp-active .pilot-stat-full {
  background-image: url("../../images/hud/stats-full-red.gif");
}

.timewarp-active .animation-hud-bp-wheel,
.timewarp-active .animation-hud-r-wheel {
  animation-duration: 0.3s;
}

.timewarp-active .b-p-tape-recorder svg circle,
.timewarp-active .b-p-tape-recorder svg path,
.timewarp-active .b-p-tape-recorder svg line {
  animation: animation-intro-hud-stroke-blinking 0.5s infinite steps(1);
}

.timewarp-active .b-p-tape-recorder-circlefilled {
  animation: animation-intro-hud-fill-blinking 0.5s infinite steps(1);
}

.timewarp-active foreignObject[data-component="hud__tape-counter-count"] {
  @apply bg-red text-orange;
}

.hud-bat-flux {
  @apply hidden md:block pt-2;
}

.hud-bat-flux-warp {
  @apply hidden;
}

.timewarp-active .hud-bat-flux-def {
  @apply md:hidden;
}

.timewarp-active .hud-bat-flux-warp {
  @apply md:block;
}
.timewarp-active div[data-component="hud__battery-inner"] span.bg-green {
  animation: animation-intro-hud-bg-blinking 0.5s infinite steps(1);
}

@keyframes pilot-stat-intro {
  0% {
    @apply w-[95%];
  }
  15% {
    @apply w-[99%];
  }
  30% {
    @apply w-[93%];
  }
  80% {
    @apply w-[100%];
  }
  100% {
    @apply w-[98%];
  }
}

.timewarp-active .pilot-stat-container {
  @apply border-red;
}

.timewarp-active .anim-pilot-stat-focus,
.timewarp-active .anim-pilot-stat-oxygen,
.timewarp-active .anim-pilot-stat-awake,
.timewarp-active .anim-pilot-stat-curiosity,
.timewarp-active .anim-pilot-stat-ability,
.timewarp-active .anim-pilot-stat-chill {
  animation-name: pilot-stat-intro;
}

.timewarp-active .anim-pilot-stat-oxygen {
  animation-delay: 0.5s;
}

.timewarp-active .anim-pilot-stat-awake {
  animation-delay: 0.35s;
}

.timewarp-active .anim-pilot-stat-curiosity {
  animation-delay: 0.25s;
}

.timewarp-active .anim-pilot-stat-ability {
  animation-delay: 0.7s;
}

.timewarp-active .anim-pilot-stat-chill {
  animation-delay: 1.2s;
}

.timewarp-active .pilot-portrait-image {
  animation: animation-intro-hud-bg-blinking 0.5s infinite steps(1);
}

.timewarp-active .mod-viz-candles {
  background-image: url("../../images/hud/mod-candles-warp.png");
  animation: animation-mod-viz-candles 0.15s steps(2) infinite;
}

.timewarp-active .mod-viz-overlay {
  opacity: 0;
}

.timewarp-active .animation-hud-bp-wheel, 
.timewarp-active .animation-hud-r-wheel {
  animation-duration: 0.3s;
}

.warp-show {
  @apply hidden;
}

.timewarp-active .warp-hide {
  @apply hidden;
}

.timewarp-active .warp-show {
  @apply block;
}
