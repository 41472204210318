.family-style-overview {
  --slnt: 0;
  --minus-slnt: 0;
  --color: lightgreen;
}

.family-style-overview__indicator > span {
  @apply block w-[1px] h-[100%];
  will-change: background;
}

.family-style-overview__row {
  @apply flex md:justify-center items-center gap-[4vw];
  color: var(--color);
  pointer-events: none;
}

.family-style-overview__row:nth-child(1)
  .family-style-overview__column:first-child {
  font-variation-settings: "slnt" var(--minus-slnt), "wght" 100;
}

.family-style-overview__row:nth-child(1)
  .family-style-overview__column:last-child {
  font-variation-settings: "slnt" var(--slnt), "wght" 100;
}

.family-style-overview__row:nth-child(2)
  .family-style-overview__column:first-child {
  font-variation-settings: "slnt" var(--minus-slnt), "wght" 250;
}

.family-style-overview__row:nth-child(2)
  .family-style-overview__column:last-child {
  font-variation-settings: "slnt" var(--slnt), "wght" 250;
}

.family-style-overview__row:nth-child(3)
  .family-style-overview__column:first-child {
  font-variation-settings: "slnt" var(--minus-slnt), "wght" 400;
}

.family-style-overview__row:nth-child(3)
  .family-style-overview__column:last-child {
  font-variation-settings: "slnt" var(--slnt), "wght" 400;
}

.family-style-overview__row:nth-child(4)
  .family-style-overview__column:first-child {
  font-variation-settings: "slnt" var(--minus-slnt), "wght" 550;
}

.family-style-overview__row:nth-child(4)
  .family-style-overview__column:last-child {
  font-variation-settings: "slnt" var(--slnt), "wght" 550;
}

.family-style-overview__row:nth-child(5)
  .family-style-overview__column:first-child {
  font-variation-settings: "slnt" var(--minus-slnt), "wght" 750;
}

.family-style-overview__row:nth-child(5)
  .family-style-overview__column:last-child {
  font-variation-settings: "slnt" var(--slnt), "wght" 750;
}

.family-style-overview__row:nth-child(6)
  .family-style-overview__column:first-child {
  font-variation-settings: "slnt" var(--minus-slnt), "wght" 900;
}

.family-style-overview__row:nth-child(6)
  .family-style-overview__column:last-child {
  font-variation-settings: "slnt" var(--slnt), "wght" 900;
}

.family-style-overview__column {
  @apply inline-block w-full text-center;
  will-change: font-variation-settings;
  transform: translateZ(0);
  pointer-events: none;
}

.family-style-overview__column:first-child {
  @apply hidden md:block;
}