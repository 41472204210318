/* @import "../../node_modules/tailwindcss/base";
@import "../../node_modules/tailwindcss/components";
@import "../../node_modules/tailwindcss/utilities"; */

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

@import "legacy-code.css";

@import "base/fonts.css";
@import "base/typography.css";
@import "base/animation.css";

@import "components/message-center.css";
@import "components/lottie-animations.css";
@import "components/section-separators.css";
@import "components/family-overview.css";
@import "components/specimen.css";
@import "components/character-set.css";
@import "components/js-toggles.css";
@import "components/hud.css";
@import "components/typetester.css";

/* TODO: convert these to use Tailwind where possible */
@import "components/intro.css";
@import "components/axes-overview.css";
@import "components/family-style-overview.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --visible-viewport-height: 100vh;
  --global-perspective: 250px;
}

html,
body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

/* Comment this out for development because it's annoying to have it locked!! */
/* body.locked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--visible-viewport-height);
  pointer-events: none;
}

@media (hover: none) {
  body.locked {
    overflow: hidden;
  }
} */

/* body::-webkit-scrollbar {
  width: 15px;
}
body::-webkit-scrollbar-thumb {
  @apply bg-gray hover:bg-green transition-colors;
}
body::-webkit-scrollbar-track {
  @apply bg-black;
  border-left: 1px solid #949494;
} */

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
}

.overlay {
}

.overlay > div {
  pointer-events: initial;
}

.js-tilt-mode-active [data-component="request-motion"] {
  @apply hidden;
}
