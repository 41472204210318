@charset "UTF-8";
@tailwind base;

.charset-cube-container {
  @apply text-[6vw] lg:text-[6vw] md:col-span-4 hidden md:flex relative self-start sticky top-[45px];
}

/* .charset-sticky-tube {
  @apply ;
} */

.charset-cube {
}

.cube-type-container {
  @apply absolute block w-full aspect-[1/1.16];
}

.cube-type {
  @apply absolute w-[33%] h-auto flex items-center justify-center;
}

.cube-type span {
  position: absolute;
}

.cube-type:before {
  content: "";
  display: block;
  padding-bottom: 100%;
  height: 0;
}

.cube-type-top {
  @apply top-[8%] left-[50%] translate-x-[-50%];
}

.cube-type-top span {
  @apply rotate-[30deg] block w-8 text-center;
}

.cube-type-left {
  @apply top-[48%] left-[25%] translate-x-[-50%];
  /* @apply w-full h-full pt-[47.5%] pl-[3.5%]; */
  /* translate-x-[13%] translate-y-[45%] */
}

.cube-type-left span {
  @apply rotate-[30deg] block w-8 text-center;
}

.cube-type-right {
  @apply top-[48%] left-[75%] translate-x-[-50%];
  /* @apply w-full h-full pt-[47.5%] pl-[47.5%]; */
  /* translate-x-[62%] translate-y-[45%] */
}

.cube-type-right span {
  @apply -rotate-[30deg] block w-8 text-center;
}

/* CHARACTER SET GRID */

.character-set-section {
  @apply flex flex-grow flex-col mb-6;
}

.character-set-container {
  @apply flex flex-row flex-grow flex-wrap text-yellow;
}

.charset-single-char {
  @apply flex text-md justify-center items-center;
  box-shadow: 1px 0 0 0 white, 0 1px 0 0 white, 1px 1px 0 0 white,
    1px 0 0 0 white inset, 0 1px 0 0 white inset;
  height: 2em;
  width: calc((100% / 10) - 0.05px);
}

@media only screen and (min-width: 768px) {
  .charset-single-char {
    height: 2.1em;
    width: calc((100% / 12) - 0.05px);
  }
}

@media only screen and (min-width: 1024px) {
  .charset-single-char {
    height: 2.2em;
    width: calc((100% / 18) - 0.05px);
  }
}

/* CHARSET LOUPE */

.charset-highlight.zoomed {
  @apply bg-black border border-white text-yellow text-center pointer-events-none transition-opacity ease-in-out w-[200px] h-[200px] md:w-[300px] md:h-[300px] text-[31vw] leading-[1.575] md:text-[22vw] md:leading-[1.575] lg:text-[12vw] lg:leading-[1.375];
}
